import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {forkJoin, startWith, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {AbstractControl, FormArray, FormControl, FormControlStatus, FormGroup, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {
    CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS,
    CustomWorkflowTransitionModel,
    CustomWorkflowTransitionRecipeTaskModel,
    ECustomWorkflowTransitionRecipeTaskName,
    ERecipeTaskCollection,
    ERecipeTaskNotificationType,
    ERecipeTaskType,
    RECIPE_TASK_PUBLICATION_ITEM_LINK,
    RecipeTaskConfig
} from '../../models/api/custom-workflow-transition.model';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {Toaster} from '../../classes/toaster.class';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {StringUtil} from '../../classes/string-util';
import {PermissionsService} from '../../api/services/permissions.service';
import {RolesApiService} from '../../api/services/roles.api.service';
import {PermissionModel} from '../../models/api/permission.model';
import {RoleModel} from '../../models/api/role.model';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {EDataFieldCollectionName, EDataFieldTypes, EFormStatus, EStickyNoteStatus} from '../../app.enums';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {
    CustomWorkflowIdentifierSettingFragmentModel,
    EIdentifierSettingFragmentType
} from '../../models/api/custom-workflow-identifier-setting.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ConditionType} from '../conditions-form/conditions.constants';
import {FormArrayMinLengthValidator} from '../../classes/validators/form-array-min-length.validator';
import {PropertyControlComponent} from '../../components/property-control/property-control.component';
import {PropertyValueModel} from '../../models/ui/property-value.model';
import {PropertyControlValidator} from '../../classes/validators/property-control.validator';
import {EReceiverType} from '../workflow-configuration-step-form/workflow-configuration-step-form.component';
import {RLValidatorRegExConstants} from '../../classes/validators/rl-validator-regex.constant';
import {PropertyControlOptions} from '../../components/property-control/property-control.options';

class TransitionForm {
    name: FormControl<string>;
    title: FormControl<string>;
    from: FormControl<DropdownItem<string>>;
    to: FormControl<DropdownItem<string>>;
    recipe: FormArray<FormGroup<RecipeTaskForm>>;
}

class RecipeTaskForm {
    name: FormControl<DropdownItem<RecipeTaskConfig>>;
    variants?: FormControl<VariantModel[]>;
    parameters?: FormGroup;
}

export interface IWorkflowConfigurationTransitionFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    workflowConfigurationSteps: CustomWorkflowStepModel[];
    workflowConfigurationTransition?: CustomWorkflowTransitionModel;
}

@Component({
    selector: 'workflow-configuration-transition-form-component',
    templateUrl: 'workflow-configuration-transition-form.component.html',
    styleUrls: ['workflow-configuration-transition-form.component.scss']
})
export class WorkflowConfigurationTransitionFormComponent implements OnInit {
    protected readonly EReceiverType = EReceiverType;

    private destroyRef = inject(DestroyRef);
    public formGroup: FormGroup<TransitionForm>;
    private saveButton: ButtonConfig;

    private workflowConfiguration: WorkflowConfigurationModel;
    public workflowConfigurationTransition: CustomWorkflowTransitionModel;
    public steps: DropdownItem<string>[];
    public recipeTasks: DropdownItem<RecipeTaskConfig>[];
    public recipeTaskOptions: DropdownItem<RecipeTaskConfig>[];
    public variants: VariantModel[];
    public permissions: PermissionModel[];
    public permissionOptions: PermissionModel[];
    public roles: RoleModel[];
    public receiverOptions: DropdownItem<string>[];

    public uploadAssetsProperties: RulePropertyModel[];
    public updateInDesignContentProperties: RulePropertyModel[];
    public propertyOptions: RulePropertyModel[];
    public fragmentTypeOptions: DropdownItem<string>[]
        = Object.keys(EIdentifierSettingFragmentType).map((type) => new DropdownItem(type, type));

    public notificationTypeOptions = Object.values(ERecipeTaskNotificationType)
        .map((notificationType) => new DropdownItem<ERecipeTaskNotificationType>(notificationType, notificationType));

    // TODO: these can also work for ERecipeTaskCollection.PUBLICATION_ITEM, currently we only use STICKY_NOTE collection
    public collectionOptions = [ERecipeTaskCollection.STICKY_NOTE]
        .map((collection) => new DropdownItem<ERecipeTaskCollection>(collection, collection));
    public allowedProperties = [new DropdownItem('status', 'status')];

    public valueTypeOptions = [ConditionType.EQUALS]; // currently only this

    public stickyNoteStatusOptions = Object.values(EStickyNoteStatus)
        .filter(status => ![EStickyNoteStatus.NEW].includes(status))
        .map((status) => new DropdownItem<EStickyNoteStatus>(status, status));

    public receiverTypeOptions: DropdownItem<EReceiverType>[] = [
        new DropdownItem('Role', EReceiverType.ROLE),
        new DropdownItem('E-mail', EReceiverType.MAIL),
        new DropdownItem('Publication item property', EReceiverType.PROPERTY)
    ]

    // Property control settings
    public static readonly PropertyControlOptions = new PropertyControlOptions(false, false, false, true, true);
    protected readonly propertyControlOptions = WorkflowConfigurationTransitionFormComponent.PropertyControlOptions;

    get recipeFormArray() {
        return this.formGroup.controls.recipe;
    }

    private taskNameSubscriptions: Subscription[] = [];
    protected readonly ECustomWorkflowTransitionRecipeTaskName = ECustomWorkflowTransitionRecipeTaskName;

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private variantService: VariantService,
                private permissionsService: PermissionsService,
                private rolesApiService: RolesApiService,
                private dataFieldsApiService: DataFieldsApiService,
                private propertyService: PropertyService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationTransitionFormData) {
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, true);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfigurationTransition());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initData(): void {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.workflowConfigurationTransition = this.modalData.workflowConfigurationTransition || new CustomWorkflowTransitionModel();
        this.steps = this.modalData.workflowConfigurationSteps.map(step =>
            new DropdownItem(step.name, step._id, false, step.icon));
        this.recipeTasks = CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS
            .map(config => new DropdownItem(config.task, config, null, null, config.description));

        this.recipeTaskOptions = this.recipeTasks;

        // prepare data once
        forkJoin({
            variants: this.variantService.getVariants(),
            permissions: this.permissionsService.getAllPermissions(),
            roles: this.rolesApiService.getAllRoles(),
            campaignItemDataFields: this.dataFieldsApiService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM),
            uploadAssetsProperties: this.propertyService.getProperties(EPropertyContext.WORKFLOW_IDENTIFIER_SETTING_UPLOAD_ASSET),
            propertyOptions: this.propertyService.getProperties(EPropertyContext.WORKFLOW_PUBLICATION_ITEM),
            updateInDesignContentProperties: this.propertyService.getProperties(EPropertyContext.INDESIGN_RULESET),
        }).pipe(takeUntilDestroyed((this.destroyRef)))
            .subscribe({
                next: result => {
                    this.variants = result.variants.items;
                    this.permissions = result.permissions;
                    this.permissionOptions = this.permissions; // assign the initial value
                    this.roles = result.roles;
                    // currently only support data field string field as receiver option
                    this.receiverOptions = result.campaignItemDataFields.filter((dataField) => dataField.dataType.type === EDataFieldTypes.STRING)
                        .map((dataField) => new DropdownItem(dataField.getTitle(), 'campaignItems.0.dataFields.' + dataField.fieldName));
                    this.uploadAssetsProperties = result.uploadAssetsProperties.items;
                    this.propertyOptions = result.propertyOptions.items;
                    // only use campaignItem properties
                    this.updateInDesignContentProperties = result.updateInDesignContentProperties.items
                        .filter((property) => property.key === 'campaignItem');

                    this.initForm();
                },
                error: Toaster.handleApiError
            });
    }

    private initForm(): void {
        const stepFrom = this.steps.find(step => step.getValue() === this.workflowConfigurationTransition.from);
        const stepTo = this.steps.find(step => step.getValue() === this.workflowConfigurationTransition.to);
        this.formGroup = new FormGroup<TransitionForm>({
            name: new FormControl(this.workflowConfigurationTransition.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            title: new FormControl(this.workflowConfigurationTransition.title),
            from: new FormControl(stepFrom, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            to: new FormControl(stepTo, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            recipe: new FormArray([], [(recipeArray: FormArray<FormGroup<RecipeTaskForm>>) => {
                    for (const [index, recipeTask] of recipeArray.controls.entries()) {
                        this.validateRecipeTasks(recipeTask, index, recipeArray);
                    }
                    return null;
                }]
            )
        });

        this.workflowConfigurationTransition.recipe?.forEach((task) => this.addTaskFormGroup(task));

        this.formGroup.statusChanges.pipe(
            startWith(this.formGroup.status),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: FormControlStatus) => this.saveButton.disabled = status !== EFormStatus.VALID);
    }

    /**
     * Add task form group to recipe form array, optionally patch value from task
     * @param {CustomWorkflowTransitionRecipeTaskModel} [task]
     */
    public addTaskFormGroup(task?: CustomWorkflowTransitionRecipeTaskModel): void {

        const formGroup =
            new FormGroup<{ name: FormControl, parameters?: FormGroup, variants?: FormControl }>({
                name: new FormControl('', Validators.required)
            });

        let firstSetup = true; // for setting data from the task

        // listen to task name changes
        const subscription = formGroup.controls.name.valueChanges
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe((name: DropdownItem<RecipeTaskConfig>) => {
                // variants control
                if (name?.getValue().recipeTaskType === ERecipeTaskType.PER_VARIANT && this.variants.length > 0) {
                    const selectedVariants = firstSetup
                        ? this.variants.filter(variant => task?.parameters?.variants?.includes(variant._id))
                        : [];
                    formGroup.addControl('variants', new FormControl(selectedVariants)); // this is optional field
                } else {
                    if (formGroup.contains('variants')) formGroup.removeControl('variants');
                }

                // parameters control
                if (formGroup.contains('parameters')) formGroup.removeControl('parameters');
                if (name?.getValue()) {
                    formGroup.addControl('parameters', this.setupParametersControl(name.getValue(), firstSetup ? task.parameters : {}));
                }

                firstSetup = false;
            });

        const selectedTaskName =
            this.recipeTasks.find((recipeTask) => recipeTask?.getValue()?.task === task?.name);

        formGroup.controls.name.patchValue(selectedTaskName);

        this.taskNameSubscriptions.push(subscription);

        this.recipeFormArray.push(formGroup);
    }

    public addFormToFormArrayByName(formArrayName: string, taskIndex: number): void {
        // all based on the parameters
        const parametersFormGroup = this.recipeFormArray.at(taskIndex).controls.parameters;

        if (parametersFormGroup.controls[formArrayName]) {
            const formGroup = new FormGroup({});
            switch (formArrayName) {
                case 'details': {
                    formGroup.addControl('label', new FormControl(null, Validators.required));
                    formGroup.addControl('path', new FormControl<PropertyValueModel>(null, [Validators.required, PropertyControlValidator()]));
                    break;
                }
                case 'fragments': {
                    formGroup.addControl('property', new FormControl(null, Validators.required));
                    formGroup.addControl('type', new FormControl(null, Validators.required));
                    break;
                }
                case 'rules': {
                    formGroup.addControl('name', new FormControl(null, Validators.required));
                    formGroup.addControl('conditions', new FormArray([], Validators.required));
                    break;
                }
                case 'values': {
                    formGroup.addControl('property', new FormControl<PropertyValueModel>(null, [Validators.required, PropertyControlValidator()]));
                    break;
                }
                case 'receivers': {
                    formGroup.addControl('type', new FormControl(null, Validators.required));
                    break;
                }
            }
            (parametersFormGroup.controls[formArrayName] as FormArray).push(formGroup);
        } else {
            Toaster.error(`Cannot find control with name "${formArrayName}" in the recipe task form`);
        }
    }

    public addConditionFormToRule(ruleIndex: number, taskIndex: number): void {
        const parametersFormGroup = this.recipeFormArray.at(taskIndex).controls.parameters;

        if (parametersFormGroup.controls['rules']) {
            const ruleFormGroup = (parametersFormGroup.controls.rules as FormArray).at(ruleIndex) as FormGroup;

            const formGroup = new FormGroup({
                property: new FormControl(null, Validators.required),
                type: new FormControl(this.valueTypeOptions[0], Validators.required),
                value: new FormControl(null, Validators.required)
            });
            (ruleFormGroup.controls['conditions'] as FormArray).push(formGroup);
        } else {
            Toaster.error(`Cannot find control with name "rules" in the recipe task form`);
        }
    }

    private setupParametersControl(taskConfig: RecipeTaskConfig, parameters: Record<string, any> = {}): FormGroup {
        const formGroup = new FormGroup({});
        switch (taskConfig.task) {
            case ECustomWorkflowTransitionRecipeTaskName.REJECT_RULES: {
                formGroup.addControl('title', new FormControl(parameters.title, Validators.required));
                formGroup.addControl('rejectionMessage', new FormControl(parameters.rejectionMessage, Validators.required));

                const selectedCollection = this.collectionOptions
                    .find((collection) => collection.getValue() === parameters.collection);
                formGroup.addControl('collection', new FormControl(selectedCollection, Validators.required));

                const rulesArray = (parameters.config?.rules || []).map((rule: { name: string, conditions: Record<string, any>[] }) => {
                    const conditionsArray = (rule.conditions || []).map((condition: { property: string, type: string, value: string }) => {
                        const selectedProperty = this.allowedProperties.find((v) => v.getValue() === condition.property);
                        const selectedType = this.valueTypeOptions.find((v) => v.getValue() === condition.type);
                        const selectedValue = this.stickyNoteStatusOptions.find((v) => v.getValue() === condition.value);

                        return new FormGroup({
                            property: new FormControl(selectedProperty, Validators.required),
                            type: new FormControl(selectedType, Validators.required),
                            value: new FormControl(selectedValue, Validators.required),
                        });
                    });

                    return new FormGroup({
                        name: new FormControl(rule.name, Validators.required),
                        conditions: new FormArray(conditionsArray, Validators.required),
                    });
                });

                formGroup.addControl('rules', new FormArray(rulesArray, Validators.required));
                break;
            }
            case ECustomWorkflowTransitionRecipeTaskName.REJECT_SIGN_OFF_ROLES: {
                formGroup.addControl('title', new FormControl(parameters.title, Validators.required));
                formGroup.addControl('rejectionMessage', new FormControl(parameters.rejectionMessage, Validators.required));
                const selectedRoles = this.roles.filter((role) => parameters.config?.roles?.includes(role.getTitle()));
                formGroup.addControl('roles', new FormControl(selectedRoles, Validators.required));
                formGroup.addControl('threshold',
                    new FormControl(parameters.config?.threshold || 0, [Validators.required, Validators.min(0), Validators.max(100)]));
                break;
            }
            case ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ASSETS: {
                const identifierSetting = parameters.identifierSetting;

                formGroup.addControl('join', new FormControl(identifierSetting?.join, Validators.required));
                formGroup.addControl('sequence', new FormControl(identifierSetting?.sequence));
                const detailsArray = (identifierSetting?.fragments || [])
                    .map((fragment: { type: EIdentifierSettingFragmentType, property?: string, fixedValue?: string }) => {
                        const selectedType = this.fragmentTypeOptions.find((type) => type.getValue() === fragment.type);
                        const formGroup = new FormGroup<{ type: FormControl, property?: FormControl, fixedValue?: FormControl }>(
                            {type: new FormControl(selectedType, Validators.required)});
                        if (selectedType.getValue() === EIdentifierSettingFragmentType.FIXED) {
                            formGroup.addControl('fixedValue', new FormControl(fragment.fixedValue));
                        }
                        if (selectedType.getValue() === EIdentifierSettingFragmentType.DERIVED) {
                            const selectedProperty = this.uploadAssetsProperties.find((property) => property.getValue() === fragment.property);
                            formGroup.addControl('property', new FormControl(selectedProperty, RLValidatorConstants.VALIDATOR_SETS.REQUIRED));
                        }
                        return formGroup;
                    });
                formGroup.addControl('fragments', new FormArray(detailsArray, FormArrayMinLengthValidator(1)));
                break;
            }
            case ECustomWorkflowTransitionRecipeTaskName.CREATE_LINKS: {
                const selectedPermissions = this.permissionOptions.filter((permission) => parameters.permissions?.includes(permission.getValue()));
                formGroup.addControl('permissions', new FormControl(selectedPermissions));
                // always save this link
                formGroup.addControl('link',
                    new FormControl({value: RECIPE_TASK_PUBLICATION_ITEM_LINK, disabled: true}, Validators.required)); // string field for now
                formGroup.addControl('linkExpiration', new FormControl(parameters.linkExpiration, Validators.min(0)));
                break;
            }
            case ECustomWorkflowTransitionRecipeTaskName.SEND_NOTIFICATIONS:
            case ECustomWorkflowTransitionRecipeTaskName.SEND_LINKS: {

                if (taskConfig.task === ECustomWorkflowTransitionRecipeTaskName.SEND_NOTIFICATIONS) {
                    const selectedNotificationType = this.notificationTypeOptions
                        .find((notificationType) => notificationType.getValue() === parameters.notification_type);
                    formGroup.addControl('notificationType', new FormControl(selectedNotificationType, Validators.required));
                }

                // any string field from campaign item
                formGroup.addControl('message', new FormControl(parameters.message));

                const receiversArray = [];
                for (const receiver of (parameters.receivers || [])) {
                    const propertyValue = PropertyControlComponent.getPropertyValueModel(receiver?.property, this.propertyOptions);
                    const type = this.receiverTypeOptions.find(option => option.getValue() === receiver.type);

                    switch (type.getValue()) {
                        case EReceiverType.ROLE: {
                            const receiverFormGroup = new FormGroup({
                                roles: new FormControl(receiver.roles.map(role => this.roles.find(option => option.getValue() === role)),
                                    Validators.required),
                                type: new FormControl(type, Validators.required)
                            });
                            receiversArray.push(receiverFormGroup);
                            break;
                        }
                        case EReceiverType.MAIL: {
                            const receiverFormGroup = new FormGroup({
                                emails: new FormControl(receiver.emails.map(email => new DropdownItem(email, email)), Validators.required),
                                type: new FormControl(type, Validators.required)
                            });
                            receiversArray.push(receiverFormGroup);
                            break;
                        }
                        case EReceiverType.PROPERTY: {
                            const receiverFormGroup = new FormGroup({
                                property: new FormControl<PropertyValueModel>(propertyValue, [Validators.required, PropertyControlValidator()]),
                                type: new FormControl(type, Validators.required)
                            });
                            receiversArray.push(receiverFormGroup);
                            break;
                        }
                    }

                }
                formGroup.addControl('receivers', new FormArray(receiversArray));

                const detailsArray = [];
                for (const property of (parameters.details || [])) {
                    const propertyValue = (taskConfig.task === ECustomWorkflowTransitionRecipeTaskName.SEND_NOTIFICATIONS)
                        ? PropertyControlComponent.getPropertyValueModel(property.path,this.propertyOptions, this.variants)
                        : PropertyControlComponent.getPropertyValueModel(property.path,this.propertyOptions);
                    const detailsFormGroup  = new FormGroup({
                        label: new FormControl(property?.label, Validators.required),
                        path: new FormControl<PropertyValueModel>(propertyValue, [Validators.required, PropertyControlValidator()])
                    });
                    detailsArray.push(detailsFormGroup);
                }
                formGroup.addControl('details', new FormArray(detailsArray));
                break;
            }
            case ECustomWorkflowTransitionRecipeTaskName.UPDATE_INDESIGN_CONTENT: {
                const valuesArray = (parameters.properties || []).map((property: string) => {
                    const propertyValue = PropertyControlComponent.getPropertyValueModel(property, this.updateInDesignContentProperties);
                    const propertyFormControl = new FormControl<PropertyValueModel>(propertyValue, [Validators.required, PropertyControlValidator()]);

                    return new FormGroup({
                        property: propertyFormControl
                    });
                });
                formGroup.addControl('values', new FormArray(valuesArray, Validators.required));
                break;
            }
            case ECustomWorkflowTransitionRecipeTaskName.AUTO_LAYOUT: {
                formGroup.addControl('columnSpan', new FormControl(parameters.columnSpan, [Validators.required, Validators.min(1)]));
                formGroup.addControl('rowSpan', new FormControl(parameters.rowSpan, [Validators.required, Validators.min(1)]));
                break;
            }
        }
        return formGroup;
    }

    public typeChanged(type: DropdownItem<EReceiverType>, taskIndex: number, receiverIndex: number): void {
        if (!type) return;

        const formGroup = (this.recipeFormArray.controls[taskIndex].controls.parameters.controls.receivers as FormArray)
            .controls[receiverIndex] as FormGroup;

        switch(type.getValue()) {
            case EReceiverType.ROLE:
                formGroup.removeControl('emails', {emitEvent: false});
                formGroup.removeControl('property', {emitEvent: false});
                formGroup.addControl('roles', new FormControl<DropdownItem<string>[]>([], [Validators.required, Validators.minLength(1)]));
                break;
            case EReceiverType.MAIL:
                formGroup.removeControl('roles', {emitEvent: false});
                formGroup.removeControl('property', {emitEvent: false});
                formGroup.addControl('emails', new FormControl<DropdownItem<string>[]>([],
                    [Validators.required, Validators.minLength(1), (control: AbstractControl) => {
                        const emails = control.value;
                        if (Array.isArray(emails) && emails.length &&
                            !emails.every(email => !!email.getValue().match(RLValidatorRegExConstants.EMAIL))) {
                            return {email: 'Invalid email address'};
                        }

                        return null;
                    }]));
                break;
            case EReceiverType.PROPERTY:
                formGroup.removeControl('roles', {emitEvent: false});
                formGroup.removeControl('emails', {emitEvent: false});
                formGroup.addControl('property', new FormControl<PropertyValueModel>(null,
                    [Validators.required, PropertyControlValidator()]))
                break;
        }
    }

    private saveWorkflowConfigurationTransition(): void {
        const transition = ModelUtil.createApiBody({
            name: this.formGroup.value.name,
            title: this.formGroup.value.title || null,
            from: this.formGroup.value.from?.getValue(),
            to: this.formGroup.value.to?.getValue(),
            recipe: this.recipeFormArray.controls.map(taskForm => {
                const taskName = taskForm.value.name.getValue().task;
                const variants = taskForm.value.variants?.map(variant => variant._id) || undefined;
                const parameters = this.formatParametersValue(taskName, taskForm.getRawValue().parameters);

                return {
                    name: taskName,
                    parameters: Object.assign({variants}, parameters)
                };
            })
        }, this.workflowConfigurationTransition._id);

        if (this.workflowConfigurationTransition._id) {
            this.workflowConfigurationService.patchWorkflowConfigurationTransition(this.workflowConfiguration._id,
                this.workflowConfigurationTransition._id, transition)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Transition updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.createWorkflowConfigurationTransition(this.workflowConfiguration._id, transition)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Transition created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    private formatParametersValue(taskName: ECustomWorkflowTransitionRecipeTaskName, parameters: Record<string, any>): Record<string, any> {
        switch (taskName) {
            case ECustomWorkflowTransitionRecipeTaskName.REJECT_RULES: {
                return {
                    title: parameters.title,
                    rejectionMessage: parameters.rejectionMessage,
                    collection: parameters.collection.getValue(),
                    config: {
                        rules: parameters.rules.map((rule: Record<string, any>) => {
                            return {
                                name: rule.name,
                                conditions: rule.conditions.map((condition: Record<string, any>) => {
                                    return {
                                        property: condition.property.getValue(),
                                        type: condition.type.getValue(),
                                        value: condition.value.getValue()
                                    }
                                })
                            }
                        })
                    }
                };
            }
            case ECustomWorkflowTransitionRecipeTaskName.REJECT_SIGN_OFF_ROLES: {
                return {
                    title: parameters.title,
                    rejectionMessage: parameters.rejectionMessage,
                    config: {
                        roles: (parameters.roles as DropdownItem<RoleModel>[]).map((role) => role.getTitle()),
                        threshold: parameters.threshold
                    }
                };
            }
            case ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ASSETS: {
                return {
                    identifierSetting: {
                        join: parameters.join,
                        sequence: parameters.sequence || false,
                        fragments: parameters.fragments.map((fragmentGroup: Record<string, any>) => {
                            const type = fragmentGroup.type.getValue();
                            const fragment: CustomWorkflowIdentifierSettingFragmentModel = {type};
                            if (type === EIdentifierSettingFragmentType.FIXED) {
                                fragment.fixedValue = fragmentGroup.fixedValue;
                            }
                            if (type === EIdentifierSettingFragmentType.DERIVED) {
                                fragment.property = fragmentGroup.property.getValue();
                            }
                            return fragment;
                        })
                    }
                };
            }
            case ECustomWorkflowTransitionRecipeTaskName.CREATE_LINKS: {
                return {
                    permissions: (parameters.permissions as DropdownItem<PermissionModel>[] || []).map((permission) => permission.getValue()),
                    link: parameters.link,
                    linkExpiration: parameters.linkExpiration
                };
            }
            case ECustomWorkflowTransitionRecipeTaskName.SEND_NOTIFICATIONS:
            case ECustomWorkflowTransitionRecipeTaskName.SEND_LINKS: {
                const result = {
                    receivers: parameters.receivers.map((receiverGroup: Record<string, any>) => {
                        const obj: Record<string, any> = {
                            type: receiverGroup.type.getValue()
                        }
                        switch (receiverGroup.type.getValue()) {
                            case EReceiverType.ROLE: {
                                obj.roles = receiverGroup.roles.map((role) => role.getValue());
                                break;
                            }
                            case EReceiverType.MAIL: {
                                obj.emails = receiverGroup.emails.map((email) => email.getValue());
                                break;
                            }
                            case EReceiverType.PROPERTY: {
                                obj.property = receiverGroup.property.path;
                                break;
                            }
                        }
                        return obj;
                    }),
                    message: parameters.message,
                    details: parameters.details.map((detailGroup: Record<string, any>) => {
                        return {
                            label: detailGroup.label,
                            path: detailGroup.path.path
                        }
                    })
                };

                if (taskName === ECustomWorkflowTransitionRecipeTaskName.SEND_NOTIFICATIONS) {
                    result['notification_type'] = parameters.notificationType.getValue();
                    result['from'] = this.formGroup.value.from.getTitle();
                    result['to'] = this.formGroup.value.to.getTitle();
                }
                return result;
            }
            case ECustomWorkflowTransitionRecipeTaskName.UPDATE_INDESIGN_CONTENT: {
                return {
                    properties: parameters.values.map((parameterValue: {property: PropertyValueModel}) => parameterValue.property.path)
                };
            }
            case ECustomWorkflowTransitionRecipeTaskName.AUTO_LAYOUT: {
                return {
                    columnSpan: parameters.columnSpan,
                    rowSpan: parameters.rowSpan
                };
            }
        }
    }

    public deleteRecipeTask(index: number): void {
        this.recipeFormArray.removeAt(index);
        // clean up subscription
        const subscription = this.taskNameSubscriptions.splice(index, 1);
        subscription[0].unsubscribe();
    }

    public deleteFormFromFormArrayByName(formArrayName: string, index: number, taskIndex: number): void {
        const parametersFormGroup = this.recipeFormArray.at(taskIndex).controls.parameters;

        if (parametersFormGroup.controls[formArrayName]) {
            (parametersFormGroup.controls[formArrayName] as FormArray).removeAt(index);
        } else {
            Toaster.error(`Cannot find control with name "${formArrayName}" in the recipe task form`);
        }
    }

    public deleteConditionFromRule(conditionIndex: number, ruleIndex: number, taskIndex: number): void {
        const parametersFormGroup = this.recipeFormArray.at(taskIndex).controls.parameters;

        if (parametersFormGroup.controls['rules']) {
            const ruleFormGroup = (parametersFormGroup.controls.rules as FormArray).at(ruleIndex) as FormGroup;
            (ruleFormGroup.controls['conditions'] as FormArray).removeAt(conditionIndex);
        } else {
            Toaster.error('Cannot find control with name "rules" in the recipe task form');
        }

    }

    private validateRecipeTasks(task: FormGroup<RecipeTaskForm>, index: number, tasksArray: FormArray<FormGroup<RecipeTaskForm>>): void {
        const recipeName = task.controls.name;
        if (recipeName.dirty && recipeName.invalid) {
            task.setErrors({message: 'Recipe task is required'});
            return;
        }

        const config = recipeName.value?.getValue();
        if (!config) return; // Recipe task still empty (only added)

        for (const [otherIndex, recipeTask] of tasksArray.controls.entries()) {
            const otherTask = recipeTask.controls.name;
            const otherTaskValue = otherTask.value?.getValue();
            if (otherTaskValue && config.task === otherTaskValue.task && index !== otherIndex) {
                task.setErrors({message: `Duplicated recipe task ${config.task}`});
                return;
            }
        }

        if (config.allowedTasksBefore?.length === 0 && index !== 0) { // means no task is allowed before this task
            task.setErrors({message: 'This recipe task must be the first one in the list'});
            return;
        }

        if (config.allowedTasksBefore?.length > 0 && index !== 0) { // means there are tasks before this
            // check if the recipe task before this one is allowed
            const taskBeforeFormGroup = tasksArray.controls[index - 1];
            const taskBeforeValue = taskBeforeFormGroup.controls.name.value.getValue();

            if (!config.allowedTasksBefore.includes(taskBeforeValue.task)) {
                task.setErrors({message: `This task cannot be after ${taskBeforeValue.task}`});
                return;
            }
        }

        for (const recipeTask of tasksArray.controls.values()) {
            const otherTask = recipeTask.controls.name;
            const otherTaskValue = otherTask.value?.getValue();
            if (otherTaskValue?.excludeTasks?.includes(config.task)) {
                task.setErrors({message: `This task can not be combined with recipe task ${otherTaskValue.task}`});
                return;
            }
        }

        if (config.onlyTask && tasksArray.controls.length > 1) {
            task.setErrors({message: 'This recipe task cannot be selected with other recipe tasks'});
            return;
        }

        task.setErrors(null);
    }

    public getRecipeTasks(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.recipeTaskOptions = [];
        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.recipeTaskOptions = this.recipeTasks.filter((task) => {
                    return task.getTitle().match(regex)?.length > 0 || task.getValue().task.match(regex)?.length > 0
                });
        } else {
            this.recipeTaskOptions = this.recipeTasks;
        }
    }

    public requestPermissionOptions(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.permissionOptions = [];
        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.permissionOptions = this.permissions.filter((permission) =>
                permission.name.match(regex)?.length > 0)
        } else {
            this.permissionOptions = this.permissions;
        }
    }
}
